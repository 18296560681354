<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <slot name="before" />
      <v-row>
        <app-text-input
          v-model="computedForm.name"
          cols="12"
          name="name"
          required
          sm="6"
        />
        <app-mobile-input
          v-model="computedForm.mobile"
          cols="12"
          name="mobile"
          persistent-placeholder
          required
          rules="mobile"
          sm="6"
        />
        <app-job-positions
          v-model="computedForm.job_position_id"
          clearable
          cols="12"
          name="job_position_id"
          sm="6"
        />
        <app-number-input
          v-model="computedForm.salary"
          :suffix="parseAttribute('sar')"
          clearable
          cols="12"
          name="salary"
          sm="6"
        />
        <app-banks
          v-model="computedForm.bank_id"
          :hint="parseAttribute('hints.bank_id')"
          clearable
          cols="12"
          name="bank_id"
          persistent-hint
          sm="6"
        />
        <app-number-input
          v-model="computedForm.age"
          :suffix="parseAttribute('age')"
          clearable
          cols="12"
          name="age"
          sm="6"
        />
        <app-service-types
          v-model="computedForm.service_type_id"
          clearable
          cols="12"
          name="service_type_id"
          sm="6"
        />
      </v-row>
      <v-row align="center">
        <app-switcher
          v-model="computedForm.have_obligations"
          :hint="parseAttribute('hints.have_obligations')"
          cols="auto"
          name="have_obligations"
          persistent-hint
        />
      </v-row>
      <slot />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'OrderInputs',
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      usersSelect: []
    }
  },
  computed: {
    computedForm: {
      get () {
        return this.form
      },
      set (v) {
        this.$emit('update:form', v)
      }
    }
  },
  methods: {}
}
</script>
