<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-form
    ref="form"
    v-slot="{invalid}"
    :errors="errors"
    :submit="submit"
  >
    <v-card elevation="10">
      <template v-if="line">
        <v-fade-transition>
          <v-card-text class="text-center">
            <v-icon
              class="mb-3"
              color="success"
              size="50"
            >
              verified
            </v-icon>
            <h2 class="success--text text-center">
              {{ line }}
            </h2>
          </v-card-text>
        </v-fade-transition>
      </template>
      <template v-else>
        <v-card-text>
          <v-container>
            <order-inputs :form.sync="form" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <app-submit
            :disabled="invalid"
            :loading="loading"
            color="success"
            large
          >
            {{ $t('custom.send_order') }}
          </app-submit>
        </v-card-actions>
      </template>
    </v-card>
  </app-form>
</template>

<script>
import { isDevelopment } from '@helpers/Tools'
import OrderInputs from '@components/order/OrderInputs'

export default {
  name: 'GuestOrderFormForm',
  components: { OrderInputs },
  data: () => ({
    loading: !1,
    form: {
      name: isDevelopment ? 'احمد' : null,
      mobile: isDevelopment ? '0590470092' : null,
      job_position_id: isDevelopment ? 1 : null,
      salary: isDevelopment ? 5000 : null,
      bank_id: isDevelopment ? 2 : null,
      age: isDevelopment ? 20 : null,
      service_type_id: isDevelopment ? 1 : null,
      have_obligations: !1
    },
    errors: {},
    line: null
  }),
  mounted () {

  },
  methods: {
    track (id) {
      this.$gtag.event('conversion', {
        send_to: 'AW-11396111869/Ux_qCOyj6asZEP27i7oq',
        value: 2.0,
        currency: 'SAR',
        transaction_id: id
      })
    },
    async submit () {
      if (this.loading) return
      this.loading = !0
      this.errors = {}
      try {
        const { _message, _success, _data } = await this.apiService.order.publicStore(this.form)
        _message && this.alertSuccess(_message)
        if (_success) {
          this.line = _message || null
          this.form = {}
          this.$refs?.form.reset()
          this.track(_data?.id)
        }
      } catch (e) {
        const { _message, _errors } = e || {}
        _message && this.alertError(_message)
        this.errors = _errors || {}
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
