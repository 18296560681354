<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->
<template>
  <v-container class="my-16">
    <v-row
      dense
    >
      <v-col cols="12">
        <v-toolbar color="primary">
          <v-toolbar-title>{{ parseAttribute('homePage.header_btn') }}</v-toolbar-title>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <guest-order-form />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { MetaInfoMixin } from '@mixins'
import GuestOrderForm from '@components/order/GuestOrderForm.vue'

export default {
  name: 'ApplyPage',
  components: { GuestOrderForm },
  // extends: SettingComponent,
  mixins: [MetaInfoMixin],
  data: () => ({})
}
</script>
